
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};
const Steps = makeShortcode("Steps");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hello Southern Company`}</h1>
    <p>{`Presentation for `}<em parentName="p">{`Lauren`}</em>{` and the `}<em parentName="p">{`Application Security Team`}</em></p>
    <p>{`Press `}<strong parentName="p">{`space`}</strong>{` on the keyboard to continue...`}</p>
    <hr></hr>
    <h1>{`Question`}</h1>
    <p>{`This question was asked in the interview: `}</p>
    <p>{`You need to compress and encrypt data. Do you encrypt or compress first?`}</p>
    <hr></hr>
    <h1>{`Answer`}</h1>
    <ul>
      <li parentName="ul"><em parentName="li">{`Compress then encrypt`}</em>{` is better for `}<em parentName="li">{`compression and performance`}</em></li>
    </ul>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Compression of encrypted data is much less effective`}</li>
        <li parentName="ul">{`However, `}<em parentName="li">{`compress first`}</em>{` has a potential `}<em parentName="li">{`security weakness`}</em></li>
      </ul>
    </Steps>
    <p>{`Press `}<strong parentName="p">{`space`}</strong>{` on the keyboard to continue...`}</p>
    <hr></hr>
    <h1>{`Security Weakness`}</h1>
    <p>{`One can break encryption of compressed HTTPS traffic in some cases.`}</p>
    <ul>
      <li parentName="ul">{`It's more secure to just encrypt, and not compress`}</li>
      <li parentName="ul">{`See `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/CRIME"
        }}>{`CRIME`}</a>{` and `}<a parentName="li" {...{
          "href": "https://github.com/nealharris/BREACH"
        }}>{`BREACH`}</a>{` attacks for details`}</li>
    </ul>
    <hr></hr>
    <h1>{`About Candidate`}</h1>
    <ul>
      <li parentName="ul">{`Name: `}<em parentName="li">{`Boris Guzner`}</em></li>
      <li parentName="ul">{`Math major and `}<em parentName="li">{`learns for living`}</em></li>
      <li parentName="ul">{`Developer backrgound`}</li>
    </ul>
    <hr></hr>
    <h1>{`Development Skills`}</h1>
    <p>{`Latest .Net Core projects`}</p>
    <ul>
      <li parentName="ul">{`ReactJS and Bootstrap on the frontend`}</li>
      <li parentName="ul">{`.net 6 and .net core REST APIs`}</li>
      <li parentName="ul">{`Entity Framework Core and SQL Server `}</li>
    </ul>
    <hr></hr>
    <h1>{`SDLC`}</h1>
    <ul>
      <li parentName="ul">{`Azure DevSecOps experience `}</li>
      <li parentName="ul">{`Azure Key Vault`}</li>
      <li parentName="ul">{`Experience securing web apps and services`}</li>
    </ul>
    <hr></hr>
    <h1>{`Networking`}</h1>
    <ul>
      <li parentName="ul">{`Understand VNet and Subnets`}</li>
      <li parentName="ul">{`Understand NSG and Firewall`}</li>
      <li parentName="ul">{`Load Balancers and WAF`}</li>
    </ul>
    <hr></hr>
    <h1>{`Internet`}</h1>
    <ul>
      <li parentName="ul">{`Domain Registration and DNS`}</li>
      <li parentName="ul">{`SSL certificates and HTTPS`}</li>
      <li parentName="ul">{`Integration with PayPal`}</li>
    </ul>
    <hr></hr>
    <h1>{`Azure`}</h1>
    <ul>
      <li parentName="ul">{`Azure Application Gateway `}</li>
      <li parentName="ul">{`Azure API Management`}</li>
      <li parentName="ul">{`Azure Authentication and Authorization `}</li>
    </ul>
    <hr></hr>
    <h1>{`Azure Active Directory`}</h1>
    <ul>
      <li parentName="ul">{`Configure Identity and MFA `}</li>
      <li parentName="ul">{`Register Applications`}</li>
      <li parentName="ul">{`Implement Single Sign On`}</li>
    </ul>
    <hr></hr>
    <h1>{`.Net 6`}</h1>
    <p>{`In 2023 Boris built online check-in for patients visiting doctor's office`}</p>
    <ul>
      <li parentName="ul">{`.Net 6, C#, Entity Framework Core + SQL Server`}</li>
      <li parentName="ul">{`Node.js + React + Bootstrap + REST API `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://checkinfast.com"
        }}>{`https://checkinfast.com`}</a></li>
    </ul>
    <hr></hr>
    <h1>{`Modern Websites`}</h1>
    <p>{`In 2020 Boris built .Net Core web apps for medical offices.`}</p>
    <p>{`This site was used to for Covid test registration  `}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ccgalabs.cairnsoftware.com/register/at"
        }}>{`https://ccgalabs.cairnsoftware.com/register/at`}</a></li>
    </ul>
    <p>{`This site was used to schedule Covid vaccine`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://shotsfast.com"
        }}>{`https://shotsfast.com`}</a>{` `}</li>
    </ul>
    <hr></hr>
    <h1>{`Animation with CSS + React`}</h1>
    <p>{`Boris wrote a simulator for a video game `}<em parentName="p">{`Battle Cats`}</em></p>
    <ul>
      <li parentName="ul">{`Tap/click on cats to simulate the battle `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://bclab.regmagik.com"
        }}>{`https://bclab.regmagik.com`}</a>{` (with audio)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://bclab.alwaysdata.net"
        }}>{`https://bclab.alwaysdata.net`}</a>{` (no audio, more cats)`}</li>
    </ul>
    <hr></hr>
    <h1>{`Links`}</h1>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Member of `}<a parentName="li" {...{
            "href": "https://stackoverflow.com/users/3220060/regmagik"
          }}>{`StackOverflow`}</a>{` for 9+ years`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/regmagik"
          }}>{`GitHub profile`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.linkedin.com/in/borisguzner/"
          }}>{`LinkedIn`}</a>{` scroll down to see recommendations`}</li>
      </ul>
    </Steps>
    <p>{`Press `}<strong parentName="p">{`space`}</strong>{` on the keyboard to continue...`}</p>
    <hr></hr>
    <p>{`THANK YOU`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/regmagik/slide"
      }}>{`The Source code for this deck is on GitHub`}</a></p>
    <p>{`Thank you for your patience`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;